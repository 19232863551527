import BSN from 'bootstrap.native/dist/bootstrap-native-v4';
import { domReady, observerClassListener } from './helpers';

domReady(() => {
  // Observe class change on the callRequest modal
  observerClassListener('#callRequest', (className) => {
    if (className.includes('show')) {
      // Prevent scroll on callRequest overlay (ps: scroll hidden is not working with ios)
      document.body.style.overflowY = 'hidden';
      document.getElementsByTagName('html')[0].style.overflowY = 'hidden';
    } else {
      document.body.style.overflowY = 'auto';
      document.getElementsByTagName('html')[0].style.overflowY = 'inherit';
    }
  });

  // Allowed modals to be opened via url hash
  const modals = ['callRequest', 'legalNotice', 'cguNotice', 'rgpdNotice'];

  function openModalFromHash() {
    // Remove '#' from hash
    const name = window.location.hash.substring(1);

    if (modals.includes(name)) {
      const el = document.getElementById(name);
      if (el) {
        new BSN.Modal(el).show();
        el.addEventListener(
          'hidden.bs.modal',
          () => {
            // clean current url by removing hash
            window.history.replaceState(null, null, ' ');
          },
          false
        );
      }
    }
  }

  // Open modals on hash change
  window.addEventListener('hashchange', openModalFromHash, false);

  // Open modals at DOM ready
  openModalFromHash();

  // Modals with `data-show` attr will be opened on DOM ready
  [...document.querySelectorAll('.modal[data-show]')].forEach((modal) => {
    new BSN.Modal(modal).show();
  });
});
