import LazyLoad from 'vanilla-lazyload';

import '../css/bootstrap.scss';
import '../css/style.scss';

// Show webpack.front.js, bootstrap.native have a special loader
import 'bootstrap.native';

import { attachNext } from './react/helper';

import './ui/animation';
import './ui/arrowsHandler';
import './ui/menu';
import './ui/sliders';

import Filters from './components/Filters';
import CallRequest from './components/CallRequest';
import FileSelector from './components/FileInput';
import NeedsFilter from './components/NeedsFilter';

attachNext('#filters-list-articles', Filters);
attachNext('#callRequestModal', CallRequest);
attachNext('[id^="file-input-"]', FileSelector);
attachNext('#needs-filter', NeedsFilter);

// eslint-disable-next-line no-new
new LazyLoad({
  elements_selector: '[data-src]',
});

// should be imported after the mount of the modals
// this script is an observer to block the scroll of the body while the modals are opened
// eslint-disable-next-line import/first
import './ui/modals';
