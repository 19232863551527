import { domReady, transitionEnter, transitionLeave } from './helpers';

domReady(() => {
  const nav = document.querySelector('#navbar');

  if (nav) {
    /*
     * Main menu navigation
     */
    let currentOpened = null;
    document.addEventListener('click', async ({ target }) => {
      // On menu item click
      if (target.classList.contains('sub-menu__opener')) {
        // no menu opened
        if (!currentOpened) {
          await transitionEnter(target);
          currentOpened = target;
        }
        // menu is already opened
        else if (currentOpened === target) {
          await transitionLeave(target);
          currentOpened = null;
        }
        // another menu is already opened
        else {
          transitionLeave(currentOpened, { transition: false });
          transitionEnter(target, { transition: false });
          currentOpened = target;
        }
      }
      // Click outside
      else if (currentOpened && !target.closest('.sub-menu')) {
        await transitionLeave(currentOpened);
        currentOpened = null;
      }
    });

    /*
     * Mobile menu toggle
     */
    const mainMenu = nav.querySelector('.main-menu');
    nav.querySelector('.main-menu__hamburger button').addEventListener('click', (evt) => {
      if (evt.currentTarget.classList.contains('toggled')) {
        transitionLeave(mainMenu);
        evt.currentTarget.classList.remove('toggled');
      } else {
        transitionEnter(mainMenu);
        evt.currentTarget.classList.add('toggled');
      }
    });

    /*
     * Submenu background image management
     */
    nav.querySelectorAll('.sub-menu').forEach((submenu) => {
      const bgImage = submenu.querySelector('.bg-image');
      submenu.addEventListener(
        'mouseenter',
        (e) => {
          if (e.target.tagName === 'A') {
            // eslint-disable-next-line no-param-reassign
            bgImage.style.backgroundImage = `url(${e.target.dataset.bgImage})`;
            submenu.classList.add('hovered');
          }
        },
        true
      );
      submenu.addEventListener(
        'mouseleave',
        (e) => {
          if (e.target.tagName === 'A') {
            // eslint-disable-next-line no-param-reassign
            // bgImage.style.backgroundImage = null;
            submenu.classList.remove('hovered');
          }
        },
        true
      );
    });
  }
});
