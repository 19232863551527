import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

import {
  disableBodyScroll,
  enableBodyScroll,
  clearAllBodyScrollLocks,
} from 'body-scroll-lock';

import close from '../../../images/close_dark.svg';

function FiltersOverlay(props) {
  const { children, filters = [], onChange, selected } = props;

  /*
   * States
   */

  const [isOpened, setIsOpened] = useState(false);
  const [hoveredFilter, setHoveredFilter] = useState();
  const [selectedFilter, setSelectedFilter] = useState(selected);

  /*
   * Disable scroll when overlay will be displayed
   */

  const overlayRef = useRef(null);
  useEffect(() => {
    return () => {
      clearAllBodyScrollLocks();
    };
  }, []);

  /*
   * Some amazing functions
   */

  const handleOpenButtonClick = () => {
    setIsOpened(true);
    disableBodyScroll(overlayRef.current);
  };
  const handleCloseButtonClick = () => {
    setIsOpened(false);
    enableBodyScroll(overlayRef.current);
  };

  const handleItemMouseEnter = (id) => {
    setHoveredFilter(id);
  };
  const handleItemMouseLeave = () => {
    setHoveredFilter(null);
  };

  const handleItemClick = async (id) => {
    setIsOpened(false);
    setSelectedFilter(id);
    enableBodyScroll(overlayRef.current);
    await onChange(id);
  };

  const getCurrentImageUrl = () => {
    const item = filters.find((el) => el.id === (hoveredFilter || selectedFilter));
    return item ? (item.homepageImage || {}).url : null;
  };

  /*
   * Generate one button line
   */
  const generateButtonLine = (item) => {
    const className = item.id === (hoveredFilter || selectedFilter) ? 'selected' : '';
    return (
      <div className="filter" key={item.id}>
        {item.url ? (
          <a
            href={item.url}
            className={className}
            onMouseEnter={() => handleItemMouseEnter(item.id)}
            onMouseLeave={handleItemMouseLeave}
          >
            {item.title}
          </a>
        ) : (
          <button
            type="button"
            className={className}
            onClick={() => handleItemClick(item.id)}
            onMouseEnter={() => handleItemMouseEnter(item.id)}
            onMouseLeave={handleItemMouseLeave}
          >
            {item.title}
          </button>
        )}
      </div>
    );
  };

  /*
   * Render
   */

  const img = getCurrentImageUrl();
  const stateClass = isOpened ? 'opened' : '';

  return (
    <>
      {children(handleOpenButtonClick)}

      <div className={`filters-overlay ${stateClass}`} ref={overlayRef}>
        {img ? <img src={img} alt="" className="illustration" /> : null}

        <button
          type="button"
          className="close-sticky button circular light bordered"
          onClick={handleCloseButtonClick}
        >
          <img src={close} alt="close" title="close" aria-hidden="true" />
        </button>

        <div className={`filters-overlay-content ${stateClass}`}>
          <div className="filtersList">{filters.map(generateButtonLine)}</div>
        </div>
      </div>
    </>
  );
}

FiltersOverlay.propTypes = {
  children: PropTypes.func.isRequired,
  filters: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      title: PropTypes.string,
    })
  ),
  selected: PropTypes.string,
  onChange: PropTypes.func.isRequired,
};

FiltersOverlay.defaultProps = {
  filters: [],
  selected: null,
};

export default FiltersOverlay;
