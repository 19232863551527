export const MOBILE_BREAK_POINT = 991;
export const CENTRAL_FIXED_COLUMN = 960;

export const IS_MOBILE =
  (window.innerWidth ||
    document.documentElement.clientWidth ||
    document.body.clientWidth) < MOBILE_BREAK_POINT;

/**
 * Invoke callback on each class change of the target
 * @param {String} querySelector the querySelector of the target to observe
 * @param {Function} callback the callback to execute
 */
export function observerClassListener(querySelector, callback) {
  const div = document.querySelector(querySelector);

  if (div) {
    const observer = new MutationObserver((mutations) => {
      mutations.forEach((mutation) => {
        if (mutation.attributeName === 'class') {
          callback([...div.classList]); // Convert into true Array
        }
      });
    });

    observer.observe(div, { attributes: true });
  }
}

export function domReady(callback) {
  if (document.readyState !== 'loading') {
    callback();
  } else if (document.addEventListener) {
    document.addEventListener('DOMContentLoaded', callback);
  } else {
    document.attachEvent('onreadystatechange', () => {
      if (document.readyState === 'complete') {
        callback();
      }
    });
  }
}

export function scrollTo(top, duration) {
  const cosParameter = (window.scrollY - top) / 2;
  let scrollCount = 0;
  let oldTimestamp = performance.now();

  function step(newTimestamp) {
    scrollCount += Math.PI / (duration / (newTimestamp - oldTimestamp));
    if (scrollCount >= Math.PI) {
      window.scrollTo(0, top);
    }
    if (window.scrollY === top) {
      return;
    }
    window.scrollTo(
      0,
      Math.round(cosParameter + cosParameter * Math.cos(scrollCount) + top)
    );
    oldTimestamp = newTimestamp;
    window.requestAnimationFrame(step);
  }

  window.requestAnimationFrame(step);
}

const transitionDefaults = {
  classEntering: 'is-entering',
  classEntered: 'is-entered',
  classLeaving: 'is-leaving',
  classLeaved: 'is-leaved',
  transition: true,
  duration: 150,
};

/**
 * Like React-CSSTransition, it will manage classes at the beginning and the end of an entering transition
 */
export const transitionEnter = (el, options) => {
  const transitionOptions = { ...transitionDefaults, ...options };

  return new Promise((resolve) => {
    if (transitionOptions.transition) {
      el.classList.remove(transitionOptions.stateLeaved);
      el.classList.add(transitionOptions.classEntering);

      setTimeout(() => {
        el.classList.add(transitionOptions.classEntered);
        el.classList.remove(transitionOptions.classEntering);
        resolve(el);
      }, transitionOptions.duration ?? options.duration);
    } else {
      el.classList.add(transitionOptions.classEntered);
      el.classList.remove(transitionOptions.stateLeaved);
      resolve(el);
    }
  });
};

/**
 * Like React-CSSTransition, it will manage classes at the beginning and the end of an leaving transition
 */
export const transitionLeave = (el, options) => {
  const transitionOptions = { ...transitionDefaults, ...options };

  return new Promise((resolve) => {
    if (transitionOptions.transition) {
      el.classList.add(transitionOptions.classLeaving);
      el.classList.remove(transitionOptions.classEntered);

      setTimeout(() => {
        el.classList.remove(transitionOptions.classLeaving);
        el.classList.add(transitionOptions.classLeaved);
        resolve(el);
      }, transitionOptions.duration ?? options.duration);
    } else {
      el.classList.add(transitionOptions.stateLeaved);
      el.classList.remove(transitionOptions.classEntered);
      resolve(el);
    }
  });
};
