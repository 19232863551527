import React from 'react';
import PropTypes from 'prop-types';

import FiltersOverlay from '../FiltersOverlay';

import filter from '../../../images/filter.svg';

function Filters({ openText, list = [] }) {
  const onChange = (id) => {
    window.location.href = id;
  };

  const current = list.find((item) => item.current === true);
  const selected = current ? current.url : null;

  const filters = list.map((item) => ({
    id: item.url,
    url: item.url,
    title: item.text,
  }));

  return (
    <FiltersOverlay filters={filters} onChange={onChange} selected={selected}>
      {(openFiltersOverlay) => (
        <div className="d-flex justify-content-end align-items-center">
          <div className="mr-3 d-lg-block d-none mainText textHeightS">{openText}</div>
          <button
            type="button"
            className="button circular light bordered small_shadow"
            onClick={openFiltersOverlay}
          >
            <img src={filter} alt="" />
          </button>
        </div>
      )}
    </FiltersOverlay>
  );
}

Filters.propTypes = {
  openText: PropTypes.string,
  list: PropTypes.array,
};

Filters.defaultProps = {
  list: [],
  openText: 'Filtrer par',
};

export default Filters;
