import React from 'react';
import ReactSelect, { components, createFilter } from 'react-select';
import { useController } from 'react-hook-form';

function Control(props) {
  return (
    <>
      <label
        className={props.isFocused || props.hasValue ? 'topped' : ''}
        style={{ pointerEvents: 'none' }}
      >
        {props.selectProps.label}
      </label>
      <components.Control {...props} />
    </>
  );
}

// Customize ReactSelect default filter method to use `label` only
const filterOption = createFilter({
  stringify: (option) => `${option.label}`,
});

function SearchableSelect({
  className,
  control,
  error,
  hideDropdownIndicator = false,
  isClearable,
  isDisabled,
  isLoading,
  label,
  name,
  openMenuOnClick = true,
  openMenuOnFocus = true,
  options,
  rules,
}) {
  const {
    field: { ref, value, onChange, ...inputProps },
  } = useController({
    name,
    control,
    rules,
    defaultValue: '',
  });

  const option = options?.find((opt) => opt.value === value) ?? null;

  const handleChange = (selected) => {
    return onChange(selected?.value ?? null);
  };

  return (
    <div className={`input-group ${className || ''}`}>
      <ReactSelect
        value={option}
        onChange={handleChange}
        options={options}
        placeholder=""
        isLoading={isLoading}
        isDisabled={isDisabled}
        isClearable={isClearable}
        openMenuOnFocus={openMenuOnFocus}
        openMenuOnClick={openMenuOnClick}
        name={name}
        inputRef={ref}
        label={label}
        components={{ Control }}
        filterOption={filterOption}
        styles={{
          container: (provided) => ({ ...provided, width: '100%' }),
          menu: (provided) => ({ ...provided, zIndex: 3 }), // input has zIndex 2, because of the floating label
          control: (provided, state) => ({
            ...provided,
            border: 0,
            borderRadius: 0,
            borderBottom: state.isFocused ? '1px solid #2E1D59' : '1px solid #999',
            boxShadow: 0,
            backgroundColor: 'transparent',
            cursor: 'pointer',
            minHeight: 0,
            height: 40,

            ':hover': {
              borderColor: state.isFocused ? '#2E1D59' : '#999',
            },
          }),
          placeholder: (provided) => ({
            ...provided,
            fontWeight: 300,
            fontSize: '15px',
            color: '#999',
          }),
          valueContainer: (provided) => ({
            ...provided,
            padding: '0 0 0 3px',
            alignSelf: 'end',
          }),
          input: (provided) => ({
            ...provided,
            padding: 0,
            margin: 0,
            marginTop: 4,
            fontSize: '0.875rem',
          }),
          singleValue: (provided) => ({
            ...provided,
            color: 'rgba(0, 0, 0, 0.87)',
            marginTop: 4,
            fontSize: '0.875rem',
          }),
          indicatorSeparator: () => {},
          dropdownIndicator: (base) => ({
            ...base,
            padding: 4,
            ...(hideDropdownIndicator ? { display: 'none' } : {}),
          }),
          clearIndicator: (base) => ({
            ...base,
            padding: 4,
          }),
          option: (provided) => ({
            ...provided,
            fontSize: '0.875rem',
            padding: '5px 10px',
          }),
        }}
        {...inputProps}
      />

      {error && <span className="mainText red xsmall">{error}</span>}
    </div>
  );
}

export default SearchableSelect;
